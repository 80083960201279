<template>
  <search-filter
      :items="products"
      keyTitleForAlphabeticalSort="title"
      :selectedFilters="[]"
      :selectedView="'list'"
      :excludedKeys="[]"
      :tableColumns="tableColumns"
      :isActionColumnEnabled="false"
      :readOnly="true"
      @columnClick="false"
  >
    <!--      result infos-->
    <template #resultInfos="{items}">
      ({{ items.length }})
      <span>{{ currency(getPreTaxAmounts(items)) }} HT</span>
    </template>

    <!--      List view-->

    <!--      Average-->
    <template #listView_cell_average="{item}">
      {{ currency(item.average) }}
    </template>

  </search-filter>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { useOutgoingInvoices } from '../../views/sales/outgoingInvoices/useOutgoingInvoices'

import SearchFilter from '../searchFilter/SearchFilter'
import i18n from '@/libs/i18n'

export default {
  components: {
    SearchFilter
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
  },
  setup (props) {
    console.log(props.products)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const tableColumns = ref([
      { key: 'article', label: i18n.t('article'), sortable: true },
      { key: 'quantity', label: i18n.t('quantity'), sortable: true },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
      { key: 'average', label: i18n.t('averageUnitPrice'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getPreTaxAmounts = (concernedItems) => {
      let preTaxAmounts = 0
      concernedItems.forEach(i => preTaxAmounts += i.preTaxAmount)
      return preTaxAmounts
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      tableColumns,

      // Computed
      // items,

      // Methods
      getPreTaxAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>