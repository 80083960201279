<template>
  <b-row>
    <b-col md="2">
      <filters-card
          :start.sync="start"
          :end.sync="end"
          :isInvoicedSelected.sync="isInvoicedSelected"
      />
    </b-col>

    <b-col md="10">
      <articles-card
          :start="start"
          :end="end"
      />
      <!--      <b-card no-body>-->
      <!--        <b-card-header class="align-items-baseline">-->
      <!--          <div>-->
      <!--            <b-card-title class="mb-25">-->
      <!--              Sales-->
      <!--            </b-card-title>-->
      <!--            <b-card-text class="mb-0">-->
      <!--              2020 Total Sales: 12.84k-->
      <!--            </b-card-text>-->
      <!--          </div>-->
      <!--        </b-card-header>-->

      <!--        <b-card-body class="pb-0">-->
      <!--          <pre>{{ articlesByMonths.products }}</pre>-->

      <!--          &lt;!&ndash; apex chart &ndash;&gt;-->
      <!--          &lt;!&ndash;            <vue-apex-charts&ndash;&gt;-->
      <!--          &lt;!&ndash;                type="line"&ndash;&gt;-->
      <!--          &lt;!&ndash;                height="240"&ndash;&gt;-->
      <!--          &lt;!&ndash;                :options="salesLine.chartOptions"&ndash;&gt;-->
      <!--          &lt;!&ndash;                :series="salesLine.series"&ndash;&gt;-->
      <!--          &lt;!&ndash;            />&ndash;&gt;-->
      <!--        </b-card-body>-->
      <!--      </b-card>-->

    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import moment from 'moment'
import FiltersCard from './components/_filters'
import useAPI from '../../../utils/useAPI'
import ArticlesCard from '../../../components/indicators/articles/ArticlesCard'
import { useIndicators } from '../../../components/indicators/useIndicators'

export default {
  components: {
    FiltersCard,
    ArticlesCard
  },
  props: {},
  setup () {
    // const {
    //   getPurchasesByMonths,
    // } = useIndicators()

    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const start = ref(moment('2021-07-01').format('YYYY-MM-DD'))
    const end = ref(moment('2022-06-30').format('YYYY-MM-DD'))
    const isInvoicedSelected = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const { orders, incomingInvoices } = useAPI()

    // const articlesByMonths = computed(() => {
    //   let purchaseByMonth = getPurchasesByMonths(
    //       orders.value,
    //       incomingInvoices.value,
    //       [],
    //       start.value,
    //       end.value
    //   )
    //
    //   let totalPreTaxAmount = 0
    //   purchaseByMonth.forEach(pc => {
    //     totalPreTaxAmount += pc.purchases.total
    //   })
    //
    //   purchaseByMonth.forEach(pc => {
    //     pc.purchases.products.forEach(p => {
    //       p.general.percent = parseFloat(
    //           (p.general.preTaxAmount / totalPreTaxAmount).toFixed(2)
    //       )
    //     })
    //   })
    //
    //   console.log(purchaseByMonth)
    //
    //   return getPurchasesByMonths(
    //       orders.value,
    //       [],
    //       [],
    //       start.value,
    //       end.value
    //   )
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      // fetchOutgoingInvoices,
      // fetchOffers,
      fetchOrders,
      fetchIncomingInvoices
    } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // fetchOutgoingInvoices()
    // fetchOffers()
    fetchOrders()
    fetchIncomingInvoices()

    return {
      // Components

      // Data
      start,
      end,
      isInvoicedSelected,

      // Computed
      // articlesByMonths,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>